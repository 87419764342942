import Button from 'components/Buttons/Button';
import FormWrapper from 'components/utilities/FormWrapper';
import InputField from 'components/utilities/InputField';
import { Stack } from 'components/utilities/Stack';
import React from 'react';

const ResetPassword = ({ GotoLogin }) => {
  return (
    <FormWrapper>
      <Stack style={{ textAlign: 'center' }}>
        <h3>Forgot your password?</h3>
        <p>Enter your email address to receive your password reset code</p>
      </Stack>
      <form>
        <InputField>
          <label htmlFor="">EMAIL</label>
          <input type="email" placeholder="Your email address" />
        </InputField>

        <Button width="100%" padding={'20px'} style={{ fontSize: '1.2rem' }}>
          Reset Password
        </Button>
        <div className="form__more">
          Back to <span onClick={() => GotoLogin()}>Sign in</span>
        </div>
      </form>
    </FormWrapper>
  );
};

export default ResetPassword;
