import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

/* ========== Icons ========== */
import { ReactComponent as VideoIcon } from 'assets/icons/svg/video_icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/svg/play_icon.svg';
import { ReactComponent as EventIcon } from 'assets/icons/svg/event_icon.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/svg/more_horizon_icon.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/svg/users_icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/svg/settings_Icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/svg/log_out_icon.svg';

import { FiSettings } from 'react-icons/fi';

/* ========== Styles ========== */
import { StyledAside } from './style';

/* ========== Contexts ========== */
import LayoutContext from 'app/contexts/LayoutContext';
import AuthContext from 'app/providers/AuthProvider';
import MyEvents from 'components/Events/PaneLayout/MyEvents';
import Button from 'components/Buttons/Button';

function Aside() {
  const { setPreview } = useContext(LayoutContext);
  const { auth, handleLogout } = useContext(AuthContext);

  /* ========== Function ========== */
  function Logout() {
    setPreview('', false);
    handleLogout();
  }

  function toggleMoreModal() {
    const moreModal = document.querySelector('.more__modal');
    moreModal.classList.toggle('closed');
  }

  /* ========== New Component ========== */
  return (
    auth && (
      <StyledAside>
        <nav className="aside__nav">
          <Link to={'/'} className="aside__nav--item active">
            <div className="svgContainer">
              <EventIcon />
            </div>
            <p>Events</p>
          </Link>

          <Link
            to={'/liveonboarding'}
            className="aside__nav--item primary-action"
            onClick={() => setPreview(<MyEvents />, true)}
          >
            <VideoIcon />
          </Link>

          <Link to={'/play'} className="aside__nav--item">
            <div className="svgContainer play">
              <PlayIcon />
            </div>
            <p>Play</p>
          </Link>
        </nav>

        <div className="svgContainer more" onClick={toggleMoreModal}>
          <MoreIcon />
        </div>

        <div className="more__modal closed">
          <Link to={'/settings'} onClick={toggleMoreModal}>
            <div className="svg setting" style={{ color: '#000' }}>
              <SettingsIcon />
            </div>
            Settings
          </Link>
          <Link to={'/settings'} onClick={toggleMoreModal}>
            <div className="svg setting" style={{ color: '#000' }}>
              <UsersIcon />
            </div>
            Invite Friend
          </Link>
          <Link to={'/settings'} onClick={toggleMoreModal}>
            <div className="svg setting" style={{ color: '#000' }}>
              <LogoutIcon />
            </div>
            Logout
          </Link>
        </div>
      </StyledAside>
    )
  );
}

export default Aside;
