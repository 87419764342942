import styled from 'styled-components';

export const Main = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ padding }) => padding};
  background-color: ${({ bgColor }) => bgColor};
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  /* transition: all 300ms ease-in-out; */

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    padding-bottom: 5.6rem;
  }
`;

Main.defaultProps = {
  bgColor: 'transparent',
  padding: '1rem'
};
