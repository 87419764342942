import React, { useContext } from 'react';

/* ========== Layouts ========== */
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import { Container } from 'components/utilities/Container';

/* ========== Components ========== */
import Button, { ButtonOutline } from 'components/Buttons/Button';
import { StyledTicketPreviewCard } from './style';
import DataContext from 'app/contexts/DataContext';
import { ImgContainer } from 'components/utilities/ImgContainer';
import LayoutContext from 'app/contexts/LayoutContext';
import EventPreview from 'components/Events/PaneLayout/Event';

import { ReactComponent as VideoIcon } from 'assets/icons/svg/video_icon.svg';
import NewEventSignal from 'assets/img/NewEventSignal.png';
import { toBeDisabled } from '@testing-library/jest-dom/dist/matchers';

export default function TicketsPreview({ Ticket }) {
  const { Events } = useContext(DataContext);
  const { setPreview, enableToastView } = useContext(LayoutContext);

  let id = localStorage.getItem('TicketId');

  Ticket = Events.find((Ticket) => Number(Ticket.id) === Number(id));

  const setEventPreview = (key) => {
    localStorage.setItem('EventId', key);
    setPreview(<EventPreview />, true, true);
  };

  /* ========== New Component ========== */
  return (
    <>
      <Container padding="4rem 2rem 2rem" style={{ fontFamily: 'Brown' }}>
        <p
          style={{
            textAlign: 'center',
            color: '#646464',
            paddingBottom: '1rem'
          }}
        >
          click ticket to view event details
        </p>
        <StyledTicketPreviewCard
          style={{ paddingBottom: '0' }}
          onClick={() => setEventPreview(Ticket.id)}
        >
          <Flex justifyContent="center">
            {Ticket.isNew && (
              <ImgContainer
                height={'2.65rem'}
                width={'2.65rem'}
                radius={'50%'}
                style={{ display: 'block', paddingBottom: '20px' }}
              >
                <img src={NewEventSignal} alt={Ticket.name} />
              </ImgContainer>
            )}

            {Ticket.isLive && (
              <div
                className=""
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '.5rem',
                  padding: '.35rem  .85rem',
                  backgroundColor: '#ff0e83',
                  borderRadius: '.5rem'
                }}
              >
                <VideoIcon /> Live
              </div>
            )}
          </Flex>
          <p
            style={{
              fontSize: '1.35rem',
              lineHeight: 1.2,
              paddingBottom: '20px',
              paddingTop: '10px'
            }}
          >
            {Ticket.name}
          </p>

          <Flex
            justifyContent={'space-between'}
            padding={'1rem 0'}
            style={{ borderTop: '1px solid gray', color: '#ffffff' }}
          >
            <p>
              {Ticket.date.day} {Ticket.date.month}
            </p>
            <p>{Ticket.time}</p>
          </Flex>
          <Stack
            gap={'.25rem'}
            padding={'1rem 0'}
            style={{ borderTop: '1px solid gray' }}
          >
            <p>2 Tickets Avaliable</p>
            <p
              style={{ color: 'red', fontSize: '1.5rem', marginTop: '.25rem' }}
            >
              {Ticket.price}
            </p>
          </Stack>

          <Flex
            justifyContent={'center'}
            gap={'1rem'}
            alignItem={'center'}
            padding={'1rem 0'}
            width={'max-content'}
            style={{
              borderTop: '1px solid gray'
            }}
          >
            <ImgContainer height={'2rem'} width={'2rem'} radius={'50%'}>
              <img src={Ticket.creator.img} alt="" />
            </ImgContainer>

            <Stack gap={'.25rem'} style={{ textAlign: 'left' }}>
              <p
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                {Ticket.creator.name}
              </p>
              <p style={{ color: 'gray' }}>{Ticket.creator?.role}</p>
            </Stack>
          </Flex>
        </StyledTicketPreviewCard>
      </Container>

      <Stack gap={'1rem'}>
        <Button padding={'1.5rem'} width={'60%'}>
          {Ticket?.isLive ? 'Join Event' : 'Share Ticket'}
        </Button>
        {Ticket?.isLive ? (
          <ButtonOutline
            disabled
            padding={'1.5rem'}
            width={'60%'}
            onClick={() => enableToastView(<CancelTicketPopUp />)}
          >
            Cancel Ticket
          </ButtonOutline>
        ) : (
          <ButtonOutline padding={'1.5rem'} width={'60%'}>
            Cancel Ticket
          </ButtonOutline>
        )}
      </Stack>
    </>
  );
}

export const CancelTicketPopUp = () => {
  return (
    <Stack gap={'2rem'}>
      <p>Are you sure you want to canel this ticket?</p>

      <Flex direction={'column'} gap={'1rem'}>
        <Button padding={'1rem'}>Yes </Button>
        <Button padding={'1rem'}>No </Button>
      </Flex>
    </Stack>
  );
};
