import styled from 'styled-components';

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  height: max-content;
  gap: 0.05rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  label {
    display: block;
    font-size: 0.85rem;
  }

  input,
  select,
  textarea {
    display: block;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.purple};
    padding: 0.85rem 1rem;
    font-size: 1.2rem;
    border-radius: 0.35rem;
  }
`;

export default InputField;
