import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ padding }) => padding};
  background-color: ${({ bgColor }) => bgColor};
`;

Container.defaultProps = {
  bgColor: 'transparent',
  padding: '1rem'
};

export const Main = styled(Container)`
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    padding-bottom: 6rem;
  }
`;
