import React, { useContext, useEffect, useState } from 'react';

/* ========== Components ========== */
import { ReactComponent as CloseIcon } from 'assets/icons/svg/close-icon.svg';

/* ========== Styles ========== */

/* ========== Import Context ========== */
import LayoutContext from 'app/contexts/LayoutContext';
import { StyledSidepane, StyledSidePaneCloser } from './style';

const SidePane = ({ component, className }) => {
  /* ========== useState ========== */
  const [event, setEvent] = useState();

  /* ========== useContext ========== */
  const { sidePane, setPreview } = useContext(LayoutContext);

  /* ========== New Component ========== */
  return (
    sidePane && (
      <StyledSidepane component={component} className={className}>
        <StyledSidePaneCloser onClick={() => setPreview('', false)}>
          <div className="svgContainer">
            <CloseIcon fill="green" stroke="white" />
          </div>
        </StyledSidePaneCloser>

        {component}
      </StyledSidepane>
    )
  );
};

export default SidePane;
