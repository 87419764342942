import React, { useContext } from 'react';

/* ========== Components ========== */
import { Stack } from 'components/utilities/Stack';
import { Flex } from 'components/utilities/Flex';
import Button, { ButtonOutline } from 'components/Buttons/Button';

/* ========== Import Context ========== */
import DataContext from 'app/contexts/DataContext';
import { Container } from 'components/utilities/Container';
import LayoutContext from 'app/contexts/LayoutContext';

export default function ManageEvents() {
  const { Events } = useContext(DataContext);
  const { enableToastView } = useContext(LayoutContext);

  /* ========== New Component ========== */

  /* ? TODO: If event is created */
  return (
    <Container padding="0 20px 20px">
      <h3 className="SidePane__title">Manage Events</h3>
      {Events &&
        Events.map((Event) => (
          <Flex
            key={Event.name}
            gap="20px"
            justifyContent="space-between"
            alignItem="center"
            style={{ marginBottom: '1rem' }}
          >
            <div className="event__time">{Event.time}</div>
            <Stack>
              <p
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.2'
                }}
              >
                {Event.name}
              </p>
            </Stack>

            <ButtonOutline
              onClick={() => enableToastView(<ManageEventsPopup />)}
              padding={'20px'}
              width={'100px'}
              style={{ justifySelf: 'flex-end' }}
              // onClick={() => setPreviewWithOverlay('', false)}
            >
              Manage
            </ButtonOutline>
          </Flex>
        ))}
    </Container>
  );
}

export const ManageEventsPopup = () => {
  return (
    <Flex direction={'column'} gap={'1rem'}>
      <Button padding={'1rem'}>Edit Event </Button>
      <ButtonOutline padding={'1rem'}>Cancel Event </ButtonOutline>
    </Flex>
  );
};
