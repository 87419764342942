import styled from 'styled-components';

export const StyledEventPreview = styled.div`
  height: 100%;
  font-family: 'Brown';

  line-height: 1.5;

  button.absolute {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    background-color: #000000c4;
    color: ${({ theme }) => theme.colors.purple};
    padding: 0.5rem;
    border-radius: 0.35rem;
  }

  .event {
    &__link {
      margin-left: auto;
      background-color: #000000c4;
      color: ${({ theme }) => theme.colors.purple};
      padding: 0.65rem 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;

      display: flex;
      align-items: center;
      gap: 0.25rem;

      svg {
        color: inherit;
        height: 1.3rem;
      }
    }

    &__guest {
      background-color: #000;
      border-radius: 0.65rem;
      padding: 0.35rem 0.65rem;

      &__profile-tag {
        width: max-content;
        display: flex;
        align-items: center;
        background-color: #000;
        border-radius: 0.65rem;
        padding: 0.35rem 0.65rem;
        gap: 0.5rem;

        img {
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
        }
      }
    }

    &__body {
      margin-top: -1.5rem;
      background-color: ${({ theme }) => theme.colors.black};
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
    }

    &__description {
      p {
        line-height: 1.3;
      }
    }

    &__action {
      width: 100%;
      padding: 1rem;
      display: grid;
      place-items: center;

      button {
        width: 80%;
        text-align: center;
        justify-content: center;
      }
    }
  }
`;

export const ProfileTag = styled.div`
  white-space: nowrap;
  height: max-content;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem 0.65rem;
  border-radius: 0.65rem;
  background-color: #000;
`;

ProfileTag.defaultProps = {
  backgroundColor: '#000'
};
