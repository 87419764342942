import React from 'react';
import styled from 'styled-components';

export const ImgContainer = ({
  height,
  width,
  oFit,
  border,
  shadow,
  radius,
  children
}) => {
  return (
    <StyledImgContainer
      height={height}
      border={border}
      radius={radius}
      oFit={oFit}
      width={width}
      shadow={shadow}
    >
      {children}
    </StyledImgContainer>
  );
};

const StyledImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height};
  width: 100%;
  max-width: ${({ width }) => width};
  pointer-events: visible;
  border: ${({ border }) => border};
  border-radius: ${({ radius }) => radius};
  box-shadow: ${({ shadow }) => shadow};
  flex-shrink: 0;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${({ oFit }) => oFit};
  }
`;

StyledImgContainer.defaultProps = {};
