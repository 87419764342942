import React, { useContext } from 'react';

import EventCard from 'components/Events/Card/Event';
import { Cards } from 'components/utilities/Cards';
import LayoutContext from 'app/contexts/LayoutContext';
import DataContext from 'app/contexts/DataContext';
import EventPreview from 'components/Events/PaneLayout/Event';
import { Tabs } from 'components/utilities/Tabs';

const Events = () => {
  /* ========== useContext ========== */
  const { setPreview } = useContext(LayoutContext);

  /* ========== useState ========== */
  const { Events } = useContext(DataContext);

  const setEventPreview = (key) => {
    localStorage.setItem('EventId', key);
    setPreview(<EventPreview />, true, true);
  };

  return (
    <>
      <Tabs alignItem={'flex-start'}>
        <div>All Events</div>
        <div>Trending</div>
        <div>My Events</div>
      </Tabs>
      {Events && (
        <Cards no="2" minSize="240px" rGap="0.35rem" cGap={'0'}>
          {Events.map((Event) => (
            <EventCard
              key={Event.id}
              Event={Event}
              click={() => setEventPreview(Event.id)}
            />
          ))}
        </Cards>
      )}
    </>
  );
};

export default Events;
