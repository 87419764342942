import React, { useContext } from 'react';

/* ========== Import Context ========== */
import DataContext from 'app/contexts/DataContext';

// import Button from 'components/Buttons/Button';
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import { ButtonOutline } from 'components/Buttons/Button';
import { Link, Navigate } from 'react-router-dom';
import { Container } from 'components/utilities/Container';
import LayoutContext from 'app/contexts/LayoutContext';
import GoLivePaneLayout from 'components/GoLive/PaneLayout/Index';

export default function MyEventsPreview() {
  const { Events } = useContext(DataContext);
  const { setPreview } = useContext(LayoutContext);

  /* ========== New Component ========== */
  return (
    <Container padding="0 20px 20px">
      <h3 className="SidePane__title">My Events</h3>
      {Events
        ? Events.map((Event) => (
            <Flex
              key={Event.name}
              gap="2rem"
              justifyContent="space-between"
              style={{ marginBottom: '1rem' }}
              alignItem={'center'}
            >
              <div className="event__time">
                {Date.now() - Number(Event.time)}
              </div>
              <Stack>
                <p
                  style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.2'
                  }}
                >
                  {Event.title}
                </p>
                <Flex justifyContent="space-between" style={{ color: 'gray' }}>
                  <span>
                    {Event.date.day} {Event.date.month}
                  </span>
                </Flex>
              </Stack>

              <Link to={'/streaming'}>
                <ButtonOutline
                  padding={'20px'}
                  width={'100px'}
                  onClick={() => {
                    setPreview(<GoLivePaneLayout />, true, true);
                    <Navigate to={'/streaming'} />;
                  }}
                >
                  {Event.ishosted ? <span>Start</span> : <span>View</span>}{' '}
                </ButtonOutline>
              </Link>
            </Flex>
          ))
        : 'no event'}
    </Container>
  );
}
