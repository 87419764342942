import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000005d;

  width: 100%;
  min-height: 100%;
  height: 10000%;

  z-index: 100;

  &.closed {
    display: none;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
`;
