import styled from 'styled-components';

export const StyledStreaming = styled.main`
  position: relative;
  width: 100%;

  #local {
    height: 100%;
    width: 100%;
  }

  .streaming__controls {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    padding: 0 2rem;

    > * {
      flex-shrink: 0;
    }

    .flex {
      display: flex;
      gap: 1rem;
    }

    .svg {
      display: grid;
      place-items: center;
      background-color: ${({ theme }) => theme.colors.black};
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
      font-size: 1.1rem;

      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.colors.darkBlack};

      &:hover {
        border-color: ${({ theme }) => theme.colors.purple};
      }

      &.setting {
        border-radius: 0.65rem;
      }

      &.gift,
      &.chat {
        background: ${({ theme }) => theme.colors.linearPurple};
        background-position: center center;
        background-size: cover;
      }

      &.end {
        background-color: ${({ theme }) => theme.colors.red};
      }
    }
  }
`;
