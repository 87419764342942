import React from 'react';
import { Stack } from 'components/utilities/Stack';
import { Flex } from 'components/utilities/Flex';
import Button, { ButtonOutline } from 'components/Buttons/Button';

import VideoAvatar from '../../../../assets/img/VideoAvatar.png';
import VideoPhoto from '../../../../assets/img/videoImg.png';

const Videos = () => {
  return (
    <div>
      <Stack
        gap={'10px'}
        style={{ backgroundColor: '#191a1d', borderRadius: '10px' }}
        padding={'20px'}
      >
        <Flex gap="10px" alignItem="center">
          <img
            style={{ height: '1.5rem', width: '1.5rem' }}
            src={VideoAvatar}
          />
          <span>Farida Adamu</span>
        </Flex>
        <Flex gap={'20px'}>
          <img
            style={{
              height: '10rem',
              width: '100%',
              objectFit: 'cover',
              borderRadius: '10px'
            }}
            src={VideoPhoto}
          />
          <Stack width={'max-content'}>
            <Button padding="20px">Play</Button>
            <ButtonOutline padding="20px">Preview</ButtonOutline>
          </Stack>
        </Flex>
        <p>Mary had a little lamb.mp4</p>
      </Stack>

      <ButtonOutline
        padding="20px"
        style={{ marginTop: '20px', marginLeft: 'auto' }}
      >
        Upload Video
      </ButtonOutline>
    </div>
  );
};

export default Videos;
