import React, { useContext, useRef } from 'react';

/* ========== Layouts ========== */
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import { Container } from 'components/utilities/Container';
import Button from 'components/Buttons/Button';
import WalletContext from 'app/contexts/WalletContext';
import FormWrapper from 'components/utilities/FormWrapper';
import InputField from 'components/utilities/InputField';

function Withdraw() {
  const { WalletBalance, setWalletBalance, toggleTab } =
    useContext(WalletContext);

  return (
    <Container name="withdraw">
      <Flex
        direction="column"
        justifyContent="center"
        style={{ textAlign: 'center' }}
      >
        <h2>Withdraw to</h2>
        <Stack padding={'2rem'} gap={'2rem'}>
          <Button
            onClick={() => toggleTab(3, WithdrawToBankAccount)}
            padding={'1.5rem'}
          >
            Bank Account
          </Button>
          <Button
            onClick={() => toggleTab(3, WithdrawWithPayPal)}
            padding={'1.5rem'}
          >
            Paypal
          </Button>
        </Stack>
      </Flex>
    </Container>
  );
}

const WithdrawToBankAccount = () => {
  const WithdrawFunds = () => {};
  return (
    <FormWrapper>
      <form onSubmit={WithdrawFunds}>
        <InputField>
          <label htmlFor="Country">Country</label>
          <select name="" id="">
            <option value=""></option>
            <option value="Nigeria">Nigeria</option>
            <option value="USA">United States</option>
          </select>
        </InputField>

        <InputField>
          <label htmlFor="Amount">Amount</label>
          <input type="number" />
        </InputField>
        <Button padding={'1.5rem'} margin={'2rem auto 0'}>
          Proceed
        </Button>
      </form>
    </FormWrapper>
  );
};

const WithdrawWithPayPal = () => {
  return (
    <div>
      <p>Withdraw with Paypal</p>
    </div>
  );
};

export default Withdraw;
