import React, { useContext, useEffect, useRef, useState } from 'react';

/* ========== Layouts ========== */

/* ========== Components ========== */
import Button from 'components/Buttons/Button';
import FormWrapper from 'components/utilities/FormWrapper';
import InputField from 'components/utilities/InputField';
import DataContext from 'app/contexts/DataContext';
import WalletContext from 'app/contexts/WalletContext';
import LayoutContext from 'app/contexts/LayoutContext';

function Deposit() {
  const { user, setUser } = useContext(DataContext);
  const { WalletBalance, setWalletBalance } = useContext(WalletContext);
  const { enableToastView } = useContext(LayoutContext);

  const amountRef = useRef();
  const MinimumDeposit = 100;

  useEffect(() => {
    amountRef.current.focus();
  }, []);

  const DepositFund = (e) => {
    let userDepositAmount = amountRef.current.value;
    e.preventDefault();

    if (userDepositAmount <= 100) {
      enableToastView(
        `less than ${user.wallet.currency}${MinimumDeposit} can not be added`,
        true
      );
      return;
    }

    user.wallet.AvailableBalance = WalletBalance + Number(userDepositAmount);

    setTimeout(() => {
      setWalletBalance(user.wallet.AvailableBalance);

      enableToastView(
        `Successfully added ${user.wallet.currency}${amountRef.current.value}`,
        true
      );

      amountRef.current.value = '';
    }, 2000);
  };

  return (
    <FormWrapper>
      <form onSubmit={DepositFund}>
        <InputField>
          <label htmlFor="Amount">Amount</label>
          <input type="number" ref={amountRef} />
        </InputField>
        <Button padding={'1.5rem'} margin={'2rem auto 0'}>
          Deposit Fund
        </Button>
      </form>
    </FormWrapper>
  );
}

export default Deposit;
