import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  width: ${({ width }) => width};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItem }) => alignItem};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
`;

Flex.defaultProps = {
  width: '100%'
};
