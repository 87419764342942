import { Container } from 'components/utilities/Container';
import React from 'react';

const Notifications = () => {
  return (
    <div>
      <Container padding={'0 20px 20px'}>
        <h4 className="SidePane__title">Notifications</h4>
      </Container>
    </div>
  );
};

export default Notifications;

const EventNotification = () => {
  return (
    <div className="event__notification">
      <p>Event notification</p>
    </div>
  );
};

const TicketNotification = () => {
  return (
    <div className="ticket__notification">
      <p>Event notification</p>
    </div>
  );
};

const paymentNotification = () => {
  return (
    <div className="ticket__notification">
      <p>Event notification</p>
    </div>
  );
};

const followNotification = () => {
  return (
    <div className="ticket__notification">
      <p>Event notification</p>
    </div>
  );
};

const inAppNotification = () => {
  return (
    <div className="ticket__notification">
      <p>Event notification</p>
    </div>
  );
};
