import React from 'react';
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';

import VideoAvatar from '../../../../assets/img/VideoAvatar.png';

const QA = () => {
  return (
    <Stack gap={'20px'}>
      <Stack
        gap={'10px'}
        style={{ backgroundColor: '#191a1d', borderRadius: '10px' }}
        padding={'20px'}
      >
        <Flex gap="10px" alignItem="center">
          <img
            style={{ height: '1.5rem', width: '1.5rem' }}
            src={VideoAvatar}
          />
          <span>Farida Adamu</span>
        </Flex>
        <p>When do the sessions begin?</p>
      </Stack>

      <Stack
        gap={'10px'}
        style={{ backgroundColor: '#191a1d', borderRadius: '10px' }}
        padding={'20px'}
      >
        <Flex gap="10px" alignItem="center">
          <img
            style={{ height: '1.5rem', width: '1.5rem' }}
            src={VideoAvatar}
          />
          <span>Farida Adamu</span>
        </Flex>
        <p>When do the sessions begin?</p>
      </Stack>
    </Stack>
  );
};

export default QA;
