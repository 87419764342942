import DataContext from 'app/contexts/DataContext';
import { Container } from 'components/utilities/Container';
import { Stack } from 'components/utilities/Stack';
import React, { useContext } from 'react';
import ReviewCard from '../Card';

const Reviews = (Event) => {
  const { Events } = useContext(DataContext);

  let id = localStorage.getItem('EventId');

  Event = Events.find((Event) => Number(Event.id) === Number(id));
  console.log(Event);

  return (
    <Container padding={'0 20px 20px'}>
      <h4 className="SidePane__title">Reviews</h4>
      <Stack gap={'1rem'}>
        {Event?.reviews &&
          Event?.reviews.map(({ name, description, img, time }, i) => (
            <ReviewCard
              key={i}
              name={name}
              description={description}
              img={img}
              time={Date.now()}
            />
          ))}
      </Stack>
    </Container>
  );
};

export default Reviews;
