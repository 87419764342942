import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate
} from 'react-router-dom';

/* ========== Themes ========== */
import { theme } from './utilities/themes/index';

/* ========== Components ========== */
// import { Main } from 'components/layouts/Main';
import Header from 'components/Header/Header';
import { Overlay } from 'components/utilities/Overlay';
import Aside from 'components/Navigation/Navigation';

/* ========== Pages ========== */
import MyTickets from 'components/Tickets/Index';
import Profile from 'components/Profile/Profile';
import SidePane from 'components/Sidebar/Sidebar';
// import CreateNewEvent from 'components/pages/Events/new';
import Events from 'components/Events/AllEvents';
import Streaming from 'components/GoLive/GoLive';

/* ========== Context and Providers ========== */
import { DataProvider } from './app/providers/DataProvider';
import { AuthProvider } from './app/providers/AuthProvider';
import LayoutContext from './app/contexts/LayoutContext';
import Toast from 'components/utilities/Toast';
import { Main } from 'components/Main/style';
import Auth from 'components/Login/Index';
import LiveOnboarding from 'components/GoLive/GoLiveOnboarding';

const StyledAppLayout = styled.div`
  position: relative;
  height: 100%;
`;

const StyledLayout = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBlack};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: calc(100vh - 5.5rem);

  transition: 300ms ease-in-out;
`;

const App = () => {
  /* ========== useStates ========== */
  const [sidePane, setSidePane] = useState(null);
  const [previewComponent, setPreviewComponent] = useState('');
  const [ComponentIsLoading, setComponentIsLoading] = useState('');
  const [overlay, setOverlay] = useState(null);
  const [toast, setToast] = useState(null);
  const [toastComponent, setToastComponent] = useState(null);
  const [offScroll, setOffScroll] = useState(null);

  /* ========== setPreview function ========== */
  const setPreview = (component, defStatus, addOverlay) => {
    setOverlay(addOverlay);
    setSidePane(defStatus);
    setOffScroll(defStatus);
    setComponentIsLoading(true);
    setPreviewComponent(component);
  };

  const enableToastView = (component, autoClose, time) => {
    setToast(true);
    setToastComponent(component);
    setOverlay(true);

    if (autoClose) {
      setTimeout(() => {
        disableToastView();
      }, time || 3000);
    }
  };

  const disableToastView = () => {
    clearTimeout();
    setToast(false);
    setToastComponent('');
    setOverlay(false);
  };

  return (
    <AuthProvider>
      <LayoutContext.Provider
        value={{
          sidePane,
          previewComponent,
          toast,
          setPreview,
          setSidePane,
          setPreviewComponent,
          setComponentIsLoading,
          enableToastView,
          disableToastView,
          offScroll,
          overlay,
          ComponentIsLoading
        }}
      >
        <ThemeProvider theme={theme}>
          {/* <GlobalStyles /> */}
          <DataProvider>
            <StyledAppLayout>
              <Router>
                <Header />
                <StyledLayout>
                  <Aside />
                  <Main>
                    {/* ========== Overlay ========== */}
                    {overlay && (
                      <Overlay onClick={() => setPreview('', false, false)} />
                    )}
                    <Routes>
                      <Route
                        path="/"
                        element={<Navigate to="/Events" replace />}
                      />
                      <Route path="/auth" element={<Auth />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/mytickets" element={<MyTickets />} />
                      <Route path="/live" element={<Streaming />} />
                      <Route
                        path="/liveonboarding"
                        element={<LiveOnboarding />}
                      />
                      {/* <Route path="/Events/new" element={<CreateNewEvent />} /> */}
                      <Route path="/Events" element={<Events />} />
                      <Route path="/streaming" element={<Streaming />} />

                      {/* Redirects */}
                      <Route
                        path="/myticket"
                        element={<Navigate to="/mytickets" replace />}
                      />
                      <Route
                        path="/tickets"
                        element={<Navigate to="/mytickets" replace />}
                      />
                      <Route
                        path="/ticket"
                        element={<Navigate to="/mytickets" replace />}
                      />
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </Main>
                  <SidePane component={previewComponent} />
                  <Toast component={toastComponent} click={disableToastView} />
                </StyledLayout>
              </Router>
            </StyledAppLayout>
          </DataProvider>
        </ThemeProvider>
      </LayoutContext.Provider>
    </AuthProvider>
  );
};

export default App;
