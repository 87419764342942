import { Container } from 'components/utilities/Container';
import { Flex } from 'components/utilities/Flex';
import { ImgContainer } from 'components/utilities/ImgContainer';
import { Stack } from 'components/utilities/Stack';
import React from 'react';

const ReviewCard = ({ name, img, description, time }) => {
  return (
    <Flex gap={'1rem'}>
      <ImgContainer height={'3rem'} width={'3rem'} radius={'50%'}>
        <img src={img} alt="" />
      </ImgContainer>
      <Stack>
        <Flex justifyContent="space-between">
          <h3 className="">{name}</h3>
          <small>{time}</small>
        </Flex>
        <p style={{ lineHeight: '1.6' }}>{description}</p>
      </Stack>
    </Flex>
  );
};

export default ReviewCard;
