import styled from 'styled-components';

export const Cards = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ no }) => no}, 1fr);
  column-gap: ${({ cGap }) => cGap};
  row-gap: ${({ rGap }) => rGap};
  padding: ${({ pad }) => pad};

  @media (min-width: 480px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(${({ minSize }) => minSize}, 1fr)
    );
  }
`;
