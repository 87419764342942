import React, { useContext, useEffect, useState } from 'react';

// import EventPreview from 'components/SidePane/EventsPreview/EventId';
import { Cards } from 'components/utilities/Cards';
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import {
  FiShare2,
  FiTwitter,
  FiYoutube,
  FiLink,
  FiMessageSquare
} from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';

// import { StyledProfilePage } from './Styles/Profile.style';
import Button, { ButtonOutline } from 'components/Buttons/Button';

import LayoutContext from 'app/contexts/LayoutContext';
import DataContext from 'app/contexts/DataContext';
import { ImgContainer } from 'components/utilities/ImgContainer';
import { StyledProfilePage } from './style';
import EventPreview from 'components/Events/PaneLayout/Event';
import { Container } from 'components/utilities/Container';

const Profile = () => {
  const { setPreview } = useContext(LayoutContext);
  const { Events, user } = useContext(DataContext);
  const [userIsFollowed, setUserIsFollowed] = useState(null);

  const newUser = user;

  const confirmDone = () => {
    alert('done');
  };

  const followUser = () => {
    newUser.stat.followers.push(newUser.id);
    showUserIsFollowed();
  };

  const unfollowUser = () => {
    newUser.stat.followers.pop();
    showUserIsFollowed();
  };

  const showUserIsFollowed = () => {
    if (newUser.stat.followers.find((follower) => follower == newUser.id)) {
      setUserIsFollowed(true);
      return;
    }
    setUserIsFollowed(false);
  };
  useEffect(() => {
    showUserIsFollowed();
  }, [userIsFollowed]);

  // const User = JSON.parse(localStorage.getItem('User'));

  /* ========== New Component ========== */
  return (
    <StyledProfilePage>
      <ImgContainer height="300px">
        <img src="assets/img/Cover.png" alt="" />
      </ImgContainer>
      <Container padding="2rem">
        <Flex
          justifyContent="flex-start"
          alignItem="center"
          className="pushUp"
          style={{ flexWrap: 'wrap' }}
        >
          <ImgContainer
            height={'12rem'}
            width={'11.8rem'}
            radius={'50%'}
            oFit={'cover'}
            shadow={'0 0 0 .65rem #090a0d'}
          >
            <img src={newUser.image} alt="" />
          </ImgContainer>
          <Flex
            justifyContent="space-between"
            style={{ marginTop: '5rem' }}
            gap={'1rem'}
          >
            <Flex gap="1rem">
              <ButtonOutline margin={'0 0 0 2rem'} gap={'.5rem'}>
                <FiMessageSquare />
                Message
              </ButtonOutline>

              {userIsFollowed ? (
                <ButtonOutline margin={0} onClick={unfollowUser}>
                  Following
                </ButtonOutline>
              ) : (
                <Button margin={0} onClick={followUser}>
                  Follow
                </Button>
              )}
            </Flex>
            <ButtonOutline margin={0} gap={'.5rem'}>
              <FiShare2 />
              Share via
            </ButtonOutline>
          </Flex>
        </Flex>

        <Flex>
          <Stack
            style={{
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
          >
            <Flex
              gap="1rem"
              style={{
                fontSize: '1.35rem',
                lineHeight: '1.3'
              }}
            >
              {newUser.username}
            </Flex>
            <div
              className="profile__name"
              style={{ color: 'gray', lineHeight: '1.3' }}
            >
              {newUser.fullname()}
            </div>
            <div
              className="profile__nationality"
              style={{ color: 'gray', lineHeight: '1.3' }}
            >
              {newUser?.nationality}
            </div>
          </Stack>
          <Flex
            gap="2rem"
            alignItem="center"
            justifyContent="flex-end"
            style={{ fontSize: '1.35rem', color: '#5458f7' }}
          >
            <a href={`${newUser.social.instagram}`} target={'__blank'}>
              <FaInstagram />
            </a>

            <a href={`${newUser.social.twitter}`} target={'__blank'}>
              <FiTwitter />
            </a>
            <a href={`${newUser.social.youtube}`} target={'__blank'}>
              <FiYoutube />
            </a>
            <a href={`${newUser.social.website}`} target={'__blank'}>
              <FiLink />
            </a>
          </Flex>
        </Flex>

        <Flex
          style={{
            flexWrap: 'wrap'
          }}
        >
          <p style={{ fontFamily: 'Brown', lineHeight: '1.3' }}>
            {newUser.bio}
          </p>

          <Flex
            gap="2rem"
            alignItem="center"
            justifyContent="flex-end"
            style={{ lineHeight: 1.6 }}
          >
            <Flex
              direction="column"
              justifyContent="center"
              width="max-content"
              alignItem="center"
            >
              {newUser.Events.length}
              <span style={{ color: 'gray' }}>Events</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
              onClick={() => newUser.showFollowing(confirmDone)}
            >
              {newUser.stat.following.length}
              <span style={{ color: 'gray' }}>Following</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
              onClick={() => newUser.showFollowers(confirmDone)}
            >
              {newUser.stat.followers.length}M
              <span style={{ color: 'gray' }}>Followers</span>
            </Flex>
            <Flex
              direction="column"
              justifyContent="center"
              alignItem="center"
              width="max-content"
            >
              {newUser.stat.views}
              <span style={{ color: 'gray' }}>Views</span>
            </Flex>
          </Flex>
        </Flex>

        <Cards pad="2rem 0" minSize="240px" cGap="20px" rGap="20px">
          {Events &&
            Events.map(({ name, img }) => (
              <div
                key={name}
                style={{ cursor: 'pointer' }}
                onClick={() => setPreview(<EventPreview />, true)}
              >
                <ImgContainer height="180px" oFit={'cover'} radius={'1rem'}>
                  <img src={img} alt={name} />
                </ImgContainer>
              </div>
            ))}
        </Cards>
      </Container>
    </StyledProfilePage>
  );
};

export default Profile;
