import styled from 'styled-components';

export const StyledProfilePage = styled.div`
  button {
    white-space: nowrap;
  }

  .profile__picture {
    height: 10rem;
    width: 10rem;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    margin-right: 2rem;
  }

  .pushUp {
    margin-top: -8rem;
  }
`;
