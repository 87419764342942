import styled from 'styled-components';

export const StyledEventCard = styled.div`
  line-height: 1;
  font-family: 'Brown';
  font-weight: lighter;
  padding: 0.25rem;
  transition: transform 300ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translateY(0.35rem);

    .event__picture {
      img {
        transform: scale(1.1);
      }
    }
  }

  .greyText {
    color: gray;
  }

  .scheduled-preview {
    position: absolute;
    top: 1rem;
    left: 1rem;

    height: max-content;
    width: max-content;

    padding: 0.25rem 0.45rem;
    z-index: 20;

    display: grid;
    place-items: center;
    gap: 0.05rem;
    border: 1px solid ${({ theme }) => theme.colors.purple};
    line-height: 1;

    color: ${({ theme }) => theme.colors.lightBlack};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0.35rem;

    font-size: 0.85rem;

    span:first-child {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;
