import React, { useContext, useEffect, useState } from 'react';

/* ========== Icons ========== */
import { ReactComponent as DepositIcon } from 'assets/icons/svg/money.svg';
import { ReactComponent as WithdrawalIcon } from 'assets/icons/svg/ticket_icon.svg';
import { ReactComponent as TransactionHistoryIcon } from 'assets/icons/svg/currency.svg';

/* ========== Components ========== */
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import { ATMCardPrototype } from 'components/utilities/ATMCardPrototype';
import Deposit from './Deposit/Index';
import TransactionHistory from './Transactions/Index';
import Withdraw from './Withdraw/Index';

import currencyFormatter from 'utilities/abstractions/currencyFormatter';
import { Container } from 'components/utilities/Container';
import { Tabs } from 'components/utilities/Tabs';
import { StyledWallet } from './styles/Wallet.style';
import DataContext from 'app/contexts/DataContext';
import WalletContext from 'app/contexts/WalletContext';

function Wallet() {
  const { user } = useContext(DataContext);
  const [WalletBalance, setWalletBalance] = useState();

  const [activeTabComponent, setActiveTabComponent] = useState('');
  const [hide, setHide] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  function toggleTab(index, component) {
    setActiveTab(index);
    setActiveTabComponent(component);
    setHide(true);
  }

  useEffect(() => {
    setWalletBalance(user.wallet.AvailableBalance);
  }, []);

  /* ========== New Component ========== */
  return (
    <WalletContext.Provider
      value={{
        WalletBalance,
        setWalletBalance,
        toggleTab
      }}
    >
      <StyledWallet>
        &nbsp;
        <Container padding="0 1rem" style={{ paddingBottom: '20px' }}>
          <ATMCardPrototype>
            <Flex justifyContent="space-between">
              <div className="wallet__balance">
                Available balance
                {WalletBalance && (
                  <span>
                    {currencyFormatter.format(Number(WalletBalance))}.00
                  </span>
                )}
              </div>
              <div className="wallet__currency">$</div>
            </Flex>

            <Flex justifyContent="space-between">
              <div className="wallet__owner">{user.fullname()}</div>
              <div className="wallet__eDate"></div>
            </Flex>
          </ATMCardPrototype>
        </Container>
        {activeTabComponent !== '' ? (
          <BackArrow
            click={() => {
              setActiveTabComponent('');
              setActiveTab(0);
              setHide(false);
            }}
          />
        ) : (
          ' '
        )}
        {!hide && (
          <Stack name="wallet-options">
            <p className="this-title">What do you want to do?</p>
            <Tabs>
              <Flex
                direction={'column'}
                justifyContent={'center'}
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => toggleTab(1, <Deposit />)}
              >
                <DepositIcon />
                <p>Deposit</p>
              </Flex>
              <Flex
                direction={'column'}
                justifyContent={'center'}
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => toggleTab(2, <TransactionHistory />)}
              >
                <TransactionHistoryIcon />
                <p>Transaction History</p>
              </Flex>
              <Flex
                direction={'column'}
                className={activeTab === 3 ? 'active' : ''}
                onClick={() => toggleTab(3, <Withdraw />)}
              >
                <WithdrawalIcon />
                <p>Withdraw</p>
              </Flex>
            </Tabs>
          </Stack>
        )}
        <Container padding={'1rem 0'} className="overflowY">
          <Flex
            alignItem={'center'}
            justifyContent={'center'}
            style={{ height: '100%' }}
          >
            {activeTabComponent}
          </Flex>
        </Container>
        <footer>
          <p>Raven Livestream Technologies</p>
          <p>&copy; 2022.</p>
        </footer>
      </StyledWallet>
    </WalletContext.Provider>
  );
}

const BackArrow = ({ click }) => {
  return (
    <div
      onClick={click}
      style={{
        cursor: 'pointer',
        display: 'flex',
        gap: '.5rem',
        alignContent: 'flex-end'
      }}
    >
      <span style={{ fontSize: '1.5rem' }}>👈🏽</span>
      <span> Back</span>
    </div>
  );
};
export default Wallet;
