import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 1rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkWhite};
  white-space: nowrap;

  div {
    flex-shrink: 0;
  }

  span {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  img {
    height: 100%;
  }

  a,
  Link {
    height: 100%;
  }
`;
