import React from 'react';
import styled from 'styled-components';

import { FiCheck } from 'react-icons/fi';

const IsVerified = () => {
  return (
    <StyledIsVerified>
      <FiCheck />
    </StyledIsVerified>
  );
};

export const StyledIsVerified = styled.div`
  height: 1.15rem;
  width: 1.15rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.purple};
  display: grid;
  place-items: center;

  svg {
    font-size: 1rem;
    color: #fff;
  }
`;
export default IsVerified;
