import React from 'react';
import TextTruncate from 'react-text-truncate';

import { Flex } from 'components/utilities/Flex';
import { ImgContainer } from 'components/utilities/ImgContainer';
import { StyledTicketCard } from './style';

const TicketCard = ({ Event, click }) => {
  return (
    <StyledTicketCard onClick={click} style={{ padding: '1rem 0.5rem 0' }}>
      <ImgContainer height={'1.65rem'} width={'1.65rem'} radius={'50%'}>
        <img src={Event.creator.img} alt={Event.name} />
      </ImgContainer>
      <div style={{ paddingBottom: '1rem', borderBottom: '1px solid gray' }}>
        <TextTruncate
          line={3}
          element="p"
          truncateText="…"
          text={Event.description}
          textTruncateChild={''}
          style={{ fontSize: '1rem' }}
        />
      </div>
      <span style={{ fontFamily: 'Brown', fontSize: '1.2rem', color: 'gray' }}>
        {Event.date.month} {Event.date.day}
      </span>
      <Flex justifyContent={'space-between'}>
        <span className="date" style={{ color: 'red' }}>
          {Event.price}
        </span>

        <span style={{ fontFamily: 'Brown', fontSize: '1rem' }}>
          {Event.time}
        </span>
      </Flex>
    </StyledTicketCard>
  );
};

export default TicketCard;
