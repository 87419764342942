import React, { useContext, useEffect, useState } from 'react';
import TicketCard from 'components/Tickets/Card/Ticket';
import { Cards } from 'components/utilities/Cards';
import DataContext from 'app/contexts/DataContext';
import TicketsPreview from 'components/Tickets/Preview/Ticket';
import LayoutContext from 'app/contexts/LayoutContext';

const MyTickets = () => {
  /* ========== useState ========== */
  const { Events } = useContext(DataContext);
  const { setPreview } = useContext(LayoutContext);

  const setTicketPreview = (key) => {
    localStorage.setItem('TicketId', key);
    setPreview(<TicketsPreview />, true, true);
  };

  return (
    Events && (
      <Cards minSize="160px" rGap="20px" cGap="20px" pad="20px" no="2">
        {Events.map((Event) => (
          <TicketCard
            key={Event.name}
            Event={Event}
            click={() => setTicketPreview(Event.id)}
          />
        ))}
      </Cards>
    )
  );
};

export default MyTickets;
