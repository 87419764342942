import styled from 'styled-components';

export const StyledLive = styled.div`
  background-image: url(/assets/img/Cover.png);
  background-size: cover;
  background-position: center center;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  .buttons-container {
    display: flex;
    flex-direction: column;

    width: 96%;
    max-width: 768px;
    padding: 5rem;
    border-radius: 0.65rem;
    margin: auto;
    gap: 1rem;
    background: ${({ theme }) => theme.colors.linearPurpleTrans};

    button {
      width: 100%;
      letter-spacing: 0.06rem;
      white-space: nowrap;
      font-size: 1.65rem;
      font-weight: 300;
      padding: 1.5rem 2rem;

      flex-direction: column;
      gap: 0.5rem;
      text-align: left;
      justify-content: flex-start;

      align-items: start;

      Link,
      a {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        text-align: left;
      }
    }

    span {
      font-size: 1.25rem;
      display: block;
      letter-spacing: 0rem;
    }
  }
`;
