import React from 'react';

const RaisedHands = () => {
  return (
    <div>
      <p>RaisedHands</p>
    </div>
  );
};

export default RaisedHands;
