import React from 'react';

const TransactionHistory = () => {
  return (
    <div>
      <p>TransactionHistory</p>
    </div>
  );
};

export default TransactionHistory;
