import React, { useContext, useState } from 'react';

import { ImgContainer } from 'components/utilities/ImgContainer';
import { ProfileTag, StyledEventPreview } from './EventStyle';
import { ReactComponent as LinkIcon } from 'assets/icons/svg/link_icon.svg';

import DataContext from 'app/contexts/DataContext';
import { Stack } from 'components/utilities/Stack';
import { Flex } from 'components/utilities/Flex';
import Button, { ButtonOutline } from 'components/Buttons/Button';
import LayoutContext from 'app/contexts/LayoutContext';
import TicketsPreview from 'components/Tickets/Preview/Ticket';
import Reviews from 'components/Reviews/PaneLayout/Reviews';

const EventPreview = ({ Event }) => {
  const { Events } = useContext(DataContext);
  const { setPreview } = useContext(LayoutContext);

  let id = localStorage.getItem('EventId');

  Event = Events.find((Event) => Number(Event.id) === Number(id));

  let AccessType = 'ticket';
  let ticketIsBought = true;

  const setTicketPreview = (key) => {
    localStorage.setItem('TicketId', key);
    setPreview(<TicketsPreview />, true, true);
  };

  const showReviews = (key) => {
    localStorage.setItem('EventId', key);

    setPreview(<Reviews />, true, true);
  };

  return (
    <StyledEventPreview>
      <ImgContainer className="event__picture" height={'280px'} oFit={'cover'}>
        <img src={Event.img} />
        <button className="absolute">💖 Add to Wishlist</button>
      </ImgContainer>
      <Stack gap=".5rem" padding="20px" className="event__body">
        <div className="event__title">
          <h3
            style={{
              fontFamily: 'Brown'
            }}
          >
            {Event.name}
          </h3>
        </div>

        <Flex justifyContent="space-between" alignItem="center">
          <Flex gap=".5rem" alignItem="center">
            <ImgContainer width="3.1rem" height="3rem" radius={'50%'}>
              <img src={Event.creator.img} alt="" />
            </ImgContainer>
            <Flex direction="column" style={{ lineHeight: 1.2 }}>
              <p>{Event.creator.name}</p>
              <small style={{ color: 'gray' }}>{Event.creator?.role}</small>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            alignItem="flex-end"
            style={{
              lineHeight: 1.2,
              fontSize: '1.2rem'
            }}
          >
            <div className="event__date" style={{ color: '#ff0e83' }}>
              {Event.date.day} {Event.date.month} {Event.date?.year}
            </div>
            <div
              className="event__time"
              style={{
                color: '#5458f7',
                fontFamily: 'Barlow',
                letterSpacing: '1px',
                fontWeight: '100'
              }}
            >
              10:47AM
            </div>
          </Flex>
        </Flex>

        <button className="event__link">
          <LinkIcon /> Copy Event Link
        </button>

        <Stack>
          <p>Guests</p>
          <Flex
            justifyContent={'flex-start'}
            gap=".5rem"
            style={{ flexWrap: 'wrap' }}
          >
            <div className="event__guest__profile-tag">
              <img src={Event.creator.img} alt="" />
              Jonathan
            </div>
            <div className="event__guest__profile-tag">
              <img src={Event.creator.img} alt="" />
              Jonathan
            </div>
            <div className="event__guest__profile-tag">
              <img src={Event.creator.img} alt="" />
              Jonathan
            </div>
            <div className="event__guest__profile-tag">
              <img src={Event.creator.img} alt="" />
              Jonathan
            </div>
          </Flex>
        </Stack>

        <Flex
          justifyContent="flex-start"
          gap=".5rem"
          style={{ marginTop: '.25rem' }}
        >
          <div className="event__tag">Party</div>
          <div className="event__tag">Party</div>
        </Flex>

        <Stack
          gap=".5rem"
          className="event__description"
          style={{ marginTop: '1rem' }}
        >
          <h3>Description</h3>
          <p>{Event.description}</p>
        </Stack>

        <Stack style={{ marginTop: '1rem' }}>
          <p>Sponsors</p>
          <Flex gap=".5rem" style={{ marginTop: '.5rem' }}>
            <ProfileTag>
              <p>Jonathan</p>
            </ProfileTag>
            <ProfileTag>
              <p>Jonathan</p>
            </ProfileTag>
          </Flex>
        </Stack>

        <Flex justifyContent="space-between">
          <p></p>
          <p>13 Million+ Going</p>
        </Flex>

        {Event?.isDone ? (
          <Button
            padding={'1.5rem'}
            width={'80%'}
            onClick={() => showReviews(Event.id)}
          >
            Reviews
          </Button>
        ) : AccessType === 'ticket' ? (
          ticketIsBought ? (
            <Flex direction="column" gap="1rem" style={{ marginTop: '1rem' }}>
              <Button padding={'1.5rem'} width={'80%'}>
                Buy more tickets
              </Button>
              <ButtonOutline
                padding={'1.5rem'}
                width={'80%'}
                onClick={() => setTicketPreview(Event.id)}
              >
                View ticket
              </ButtonOutline>
            </Flex>
          ) : (
            <Button padding={'1.5rem'} width={'80%'}>
              Buy for {Event.price}
            </Button>
          )
        ) : (
          <Button padding={'1.5rem'} width={'80%'}>
            Buy tickets to share
          </Button>
        )}
      </Stack>
    </StyledEventPreview>
  );
};

export default EventPreview;
