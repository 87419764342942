import LayoutContext from 'app/contexts/LayoutContext';
import React, { useContext } from 'react';
import './Toast.css';

const Toast = ({ component, click }) => {
  const { toast } = useContext(LayoutContext);
  let canClose = true;

  return (
    toast && (
      <div className="toast-container" data-position="center" onClick={click}>
        <div className={canClose ? 'toast can-close' : 'toast'}>
          {component}
        </div>
      </div>
    )
  );
};

export default Toast;
