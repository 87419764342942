import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;

  display: grid;
  place-items: center;

  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ radius }) => radius};
  border: 2px solid transparent;
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.purple};
  }
`;

IconWrapper.defaultProps = {
  height: '2.5rem',
  width: '2.5rem',
  radius: '50%'
  //   border: 'border: 2px solid transparent;'
};
