import styled from 'styled-components';

export const StyledHeader = styled.header`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;

  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colors.lightBlack};
  color: ${({ theme }) => theme.colors.darkGray};
  gap: 2rem;

  position: relative;
  z-index: 1000;

  .menu {
    transition: 0.35s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  svg {
    stroke: ${({ theme }) => theme.colors.white};
    height: 1.5rem;
  }

  .search {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    input {
      width: 60%;
      border-radius: 0.65rem;
      background-color: ${({ theme }) => theme.colors.darkBlack};
      border: none;
      padding: 1rem;
      font-family: 'Brown';
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.darkWhite};

      &::placeholder {
        font-size: 1.1rem;
      }
    }

    @media (max-width: ${({ theme }) => theme.screens.minSize.ipad}) {
      display: none !important;
    }
  }

  .searchButton {
    background: ${({ theme }) => theme.colors.purple};
    padding: 0.65rem 1.5rem;
    border-radius: 0.65rem;
    display: grid;
    place-items: center;

    svg {
      height: 1.35rem;
    }
  }

  .notification-count {
    position: absolute;

    top: -10px;
    right: -5px;
    background-color: red;

    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    font-size: 0.5rem;
    font-weight: bold;
    line-height: 0;
    color: white;

    display: grid;
    place-items: center;
  }
`;

export const HeaderNav = styled.nav`
  /* display: none; */

  @media (min-width: ${({ theme }) => theme.screens.minSize.ipad}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;
  }
`;
