import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ labelColor }) => labelColor};
  border-radius: 0.65rem;
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  font-size: ${({ size }) => size};
  font-weight: 500;
  margin: ${({ margin }) => margin};
  border: 1.5px solid transparent;
  line-height: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap};

  text-align: center;
  white-space: nowrap;

  cursor: pointer;

  transition: background-color 300ms ease-in-out;

  :hover {
    background-color: transparent;
    border-color: ${({ bgColor }) => bgColor};
  }

  :disabled {
    /* pointer-events: none; */
    cursor: not-allowed;
    opacity: 0.3;

    &:hover {
      background-color: initial;
      border-color: ${({ bgColor }) => bgColor};
    }
  }
`;

export const ButtonOutline = styled(Button)`
  color: ${({ labelColor }) => labelColor};
  background-color: ${({ theme }) => theme.colors.darkBlack};
  border: 1.5px solid ${({ bgColor }) => bgColor};

  :hover {
    background-color: ${({ bgColor }) => bgColor};
    border-color: transparent;
  }
`;

Button.defaultProps = {
  width: 'max-content',
  padding: '.5rem 1rem',
  bgColor: '#5457f7',
  labelColor: '#fff',
  size: '1rem',
  margin: 'auto'
};

ButtonOutline.defaultProps = {
  width: 'max-content',
  padding: '.5rem 1rem',
  bgColor: '#5457f7',
  labelColor: '#fff',
  size: '1rem',
  margin: 'auto'
};

export default Button;
