import React, { useEffect, useState } from 'react';
import DataContext from 'app/contexts/DataContext';
import axios from 'axios';

export const DataProvider = ({ children }) => {
  const [Events, setEvents] = useState(null);
  const [myEvents, setMyEvents] = useState(null);

  const [user, setUser] = useState({
    id: 1,

    name: {
      first: 'Paul',
      middle: 'Chukwudi',
      last: 'Ishaili'
    },

    fullname: () => {
      return `${user.name.first} ${user.name.last}`;
    },

    bio: 'Software Engineer || Frontend Developer || Tech Writer. Currently developing lead-generating websites and online platforms.',

    image: 'assets/img/events/event.png',

    username: 'mrpaulishaili',

    wallet: {
      currency: '$',
      AvailableBalance: 10
    },

    // This is pre-generated during the onboarding or profile completion process.
    social: {
      instagram: 'https://instagram.com/mrpaulishaili',
      facebook: 'https://facebook.com/mrpaulishaili',
      twitter: 'https://twitter.com/@mrpaulishaili',
      youtube: 'https://youtube.com/user/mrpaulishaili',
      website: 'https://about.me/mrp'
    },

    stat: {
      followers: [
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user'
      ],
      following: [
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user',
        'user'
      ],
      views: '54',
      events: ''
    },

    Events: [
      { eventID: '', accessType: '' },
      { eventID: '', accessType: '' },
      { eventID: '', accessType: '' }
    ],

    // Tickets are binded to their Events' ID
    Tickets: [
      { ticketID: '', eventID: '3' },
      { ticketID: '', eventID: '4' }
    ],

    // Premapped
    messages: [],

    notification: {
      status: true,
      count: 3
    },

    readNotification: () => {
      user.notification.status = false;
      user.notification.count = --user.notification.count;
    },

    showFollowers: (nextAction) => {
      user.stat.followers.map((follower) => {
        console.log(follower);
      });
      nextAction();
    },

    showFollowing: (nextAction) => {
      user.stat.following.map((follower) => {
        console.log(follower);
      });
      nextAction();
    }
  });

  useEffect(() => {
    fetch('./data/Events.json')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEvents(data);
        setMyEvents(Events);
      });
  }, []);

  useEffect(() => {
    let config = {
      method: 'get',
      url: 'https://raven-livestream-bn.herokuapp.com/api/v1/users/5edded09f228624694c7bffa/events',
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlZGRlZDA5ZjIyODYyNDY5NGM3YmZmYSIsImlhdCI6MTU5MjU0NzY4OSwiZXhwIjoxNjI0MDgzNjg5fQ.aEg-EjkxoRIm_Poz4lZOmMg4eHzCuxftvJZlx7ulqvI'
        // ...data.getHeaders()
      }
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <DataContext.Provider value={{ Events, myEvents, user, setUser }}>
      {children}
    </DataContext.Provider>
  );
};
