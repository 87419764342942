import styled from 'styled-components';

export const ATMCardPrototype = styled.div`
  position: relative;

  min-height: 180px;
  height: 20vh;
  max-width: 350px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  border-radius: 1rem;
  background: radial-gradient(100% 100% at 0% 0%, #ffffff66 0%, #ffffff00 100%);

  ::after,
  ::before {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    content: '';

    border-radius: 1rem;
  }

  ::after {
    z-index: -2;
    height: calc(100% + 3px);
    width: calc(100% + 3px);
    background: radial-gradient(
      2.03% 50% at 50% 50%,
      #f53e02 0%,
      #f53e0294 50%
    );
  }

  ::before {
    z-index: -1;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.darkBlack};
  }

  .wallet {
    &__currency {
      height: 2.5rem;
      width: 2.5rem;
      background-color: #84848665;
      display: grid;
      place-items: center;
      border-radius: 50%;
    }

    &__balance {
      color: ${({ theme }) => theme.colors.white};

      span {
        margin-top: 0.1rem;
        display: block;
        font-size: 1.5rem;
      }
    }
  }
`;
