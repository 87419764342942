import React from 'react';
import { StyledEventCard } from './style';

/* ========== Utilities ========== */
import { Flex } from 'components/utilities/Flex';
import { Stack } from 'components/utilities/Stack';
import { ImgContainer } from 'components/utilities/ImgContainer';
import IsVerified from 'components/utilities/isVerified';

const EventCard = ({ Event, click }) => {
  return (
    <StyledEventCard onClick={click}>
      <Flex gap={'1rem'} alignItem="center">
        <ImgContainer height={'1.8rem'} width={'1.8rem'} radius={'50%'}>
          <img src={Event.creator.img} alt={Event.creator.name} />
        </ImgContainer>
        <Stack
          className={'greyText'}
          gap={'.15rem'}
          style={{ marginBottom: '.25rem' }}
        >
          <Flex gap={'1rem'}>
            <div style={{ color: 'white' }}>{Event.creator.name}</div>
            <div style={{ color: '#848486' }}>
              {Event.creator?.isVerified && <IsVerified />}
            </div>
          </Flex>
          {Event.time}
        </Stack>
        <p style={{ marginLeft: 'auto', fontSize: '1.25rem', color: '' }}>
          {Event.price}
        </p>
      </Flex>
      <Stack>
        <div className="scheduled-preview">
          <span>{Event.date.month}</span>
          <span>{Event.date.day}</span>
        </div>
        <ImgContainer
          height={'180px'}
          border={'4px solid #141518'}
          oFit={'cover'}
          radius={'1rem'}
        >
          <img src={Event.img} alt="" />
        </ImgContainer>
        <span
          style={{
            textAlign: 'center',
            marginTop: '.25rem',
            marginBottom: '.2rem'
          }}
        >
          {Event.name}
        </span>
      </Stack>
    </StyledEventCard>
  );
};

export default EventCard;

/* ========== Styling ========== */
