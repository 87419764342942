import React, { useContext, useEffect, useState } from 'react';

import { Main } from 'components/utilities/Container';

import FormWrapper, { Wrapper } from 'components/utilities/FormWrapper';

import { Stack } from 'components/utilities/Stack';
import InputField from 'components/utilities/InputField';
import Button from 'components/Buttons/Button';
import AuthContext from 'app/providers/AuthProvider';
import ResetPassword from './ResetPassword';

export default function Auth() {
  /* ========== useState ========== */
  const [isSignUp, setIsSignUp] = useState(false);
  const [toResetPassword, setToResetPassword] = useState(false);

  const { setAuth } = useContext(AuthContext);

  function toggleTab(index) {
    setIsSignUp(!isSignUp);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setAuth(true);
  };

  const GotoLogin = () => {
    setToResetPassword(false);
    console.log('click');
  };

  return (
    <Main style={{ display: 'grid', placeItems: 'center', padding: '20px' }}>
      <Wrapper
        direction="column"
        height="max-content"
        width="520px"
        jContent="center"
        aItems="center"
        gap="0"
        style={{ backgroundColor: '#141414', borderRadius: '10px' }}
      >
        {toResetPassword ? (
          <ResetPassword GotoLogin={GotoLogin} />
        ) : (
          <>
            <Stack style={{ textAlign: 'center' }}>
              <h3>{isSignUp ? 'Get started' : 'Log in'}</h3>
              <p>
                {isSignUp
                  ? 'Create an account to get access'
                  : 'Please enter your details to enter your account'}{' '}
              </p>
            </Stack>

            <FormWrapper>
              <form action="" onSubmit={handleSubmit}>
                {isSignUp && (
                  <>
                    <InputField>
                      <label htmlFor="Fullname">Fullname</label>
                      <input type="text" />
                    </InputField>
                    <InputField>
                      <label htmlFor="Username">Username</label>
                      <input type="text" />
                    </InputField>
                    <InputField>
                      <label htmlFor="DOB">Date of Birth</label>
                      <input type="date" />
                    </InputField>
                  </>
                )}
                <InputField>
                  <label htmlFor="Email">Email Address</label>
                  <input type="email" />
                </InputField>
                <InputField>
                  <label htmlFor="password">Password</label>
                  <input type="password" />
                </InputField>
                {isSignUp && (
                  <InputField>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" />
                  </InputField>
                )}
                <p
                  className="form__forgotPassword"
                  onClick={() => setToResetPassword(true)}
                  style={{ marginLeft: 'auto' }}
                >
                  Forgot password?
                </p>
                <Button
                  width="100%"
                  padding={'20px'}
                  style={{ fontSize: '1.2rem' }}
                >
                  {isSignUp ? 'Signup' : 'Login'}
                </Button>

                <div className="form__more" style={{ textAlign: 'center' }}>
                  {isSignUp ? (
                    <>
                      Already have an account?
                      <span onClick={toggleTab}> Log In</span>
                    </>
                  ) : (
                    <>
                      Don’t have an account?
                      <span onClick={toggleTab}> Sign up </span>
                    </>
                  )}
                </div>
              </form>
            </FormWrapper>
          </>
        )}
      </Wrapper>
    </Main>
  );
}
