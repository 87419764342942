import React, { useContext, useState } from 'react';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';

/* ========== Icons ========== */
import { ReactComponent as SearchIcon } from 'assets/icons/svg/search_icon.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/svg/wallet_icon.svg';
import { ReactComponent as EventIcon } from 'assets/icons/svg/Plan_Event.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/svg/notification_icon.svg';
import { ReactComponent as TicketIcon } from 'assets/icons/svg/ticket_icon.svg';
import { FiMenu } from 'react-icons/fi';

/* ========== Import Context ========== */
import LayoutContext from 'app/contexts/LayoutContext';
import AuthContext from 'app/providers/AuthProvider';
import { LogoContainer } from 'components/utilities/Logo';
import { ImgContainer } from 'components/utilities/ImgContainer';
import { IconWrapper } from 'components/utilities/IconWrapper';
import { Flex } from 'components/utilities/Flex';
import ManageEvents from 'components/Events/PaneLayout/ManageEvent';
import Notifications from 'components/Notifications/Notifications';
import Wallet from 'components/Wallet/Index';
import DataContext from 'app/contexts/DataContext';

/* ========== Styles ========== */
import { HeaderNav, StyledHeader } from './style';

const Header = () => {
  const { user } = useContext(DataContext);

  const newUser = user;

  const { setPreview } = useContext(LayoutContext);
  const [notificationCount, setNotificationCount] = useState(
    newUser.notification.count
  );
  const { auth } = useContext(AuthContext);

  /* ========== New Component ========== */
  return (
    <StyledHeader>
      <Link to={'/'}>
        <LogoContainer>
          <img
            src={'../../assets/img/ravenrevampedlogo.png'}
            alt="Raven Livestream logo"
            style={{ height: '3rem', width: '3rem' }}
          />

          <span className="hide-mobile">Raven Livestream</span>
        </LogoContainer>
      </Link>
      {auth ? (
        <>
          <HeaderNav>
            <div className="search">
              <input
                className="hide-mobile"
                type="text"
                placeholder="Search for events, people and ticket"
              />
              <Link to={'/search'}>
                <div className="searchButton">
                  <SearchIcon />
                </div>
              </Link>
            </div>

            <Flex gap="1.5rem" width="max-content">
              <IconWrapper onClick={() => setPreview(<Wallet />, true, true)}>
                <WalletIcon />
              </IconWrapper>

              <IconWrapper
                onClick={() => setPreview(<ManageEvents />, true, true)}
              >
                <EventIcon />
              </IconWrapper>

              <IconWrapper
                onClick={() => {
                  newUser.readNotification();
                  setNotificationCount(newUser.notification.count);
                  setPreview(<Notifications />, true, true);
                }}
              >
                <NotificationIcon />
                {notificationCount >= 1 ? (
                  <div className="notification-count">{notificationCount}</div>
                ) : (
                  ''
                )}
              </IconWrapper>

              <Link to={'/tickets'}>
                <IconWrapper>
                  <TicketIcon />
                </IconWrapper>
              </Link>

              <Link to={'/profile'}>
                <IconWrapper>
                  <ImgContainer height={'100%'} oFit={'cover'} radius={'50%'}>
                    <img src={newUser.image} alt="" />
                  </ImgContainer>
                </IconWrapper>
              </Link>
            </Flex>
          </HeaderNav>
        </>
      ) : (
        ''
      )}
    </StyledHeader>
  );
};

export default Header;
