import styled from 'styled-components';

export const Stack = styled.div`
  position: relative;
  display: grid;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
`;

Stack.defaultProps = {
  width: '100%'
};
