import styled from 'styled-components';

export const StyledWallet = styled.div`
  padding: 1rem 2rem;
  /* overflow-y: hidden; */
  color: ${({ theme }) => theme.colors.white}; //.8 transparent
  padding-bottom: 6rem;

  .SidePane__title {
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  .container {
    padding: 1rem;

    &:first-of-type {
      padding: 1rem 1.5rem;
    }
  }

  .this-title {
    font-size: 1.2rem;
    letter-spacing: 0.1px;
    font-weight: 300;
    font-family: 'Barlow';
    padding-bottom: 1rem;
    text-align: center;
  }

  .wallet-activity {
    line-height: 1.2;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    letter-spacing: 0.1px;

    .illustration {
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors.black};
      border-radius: 0.65rem;
    }

    text-align: center;
    width: 100%;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.colors.lightGray};
    padding: 1rem;
    font-family: 'Brown';

    @media (max-width: ${({ theme }) => theme.screens.minSize.ipad}) {
      display: none !important;
    }
  }
`;
