export const theme = {
  dark: {},
  light: {},

  colors: {
    black: '#141518',
    darkBlack: '#090a0d',
    lightBlack: '#191a1d',
    purple: '#5458f7',
    pink: '#ff0e83',
    red: '#ff5649',
    gray: '#646464',
    lightGray: '#848486',
    darkGray: '#47484a',
    white: '#ffffff',
    darkWhite: '#bdbdbd',
    linearPurple: 'linear-gradient(to bottom, #6827f9 0%, #2f80ed 100%)',
    linearPurpleTrans:
      'linear-gradient(to bottom, #6a27f98f 0%, #2f81ed8a 100%)'
  },

  screens: {
    minSize: {
      phone: '0px',
      tablet: '480px',
      ipad: '768px',
      laptop: '920px',
      largeScreen: '1360px'
    }
  }
};
