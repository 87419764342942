import styled from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray};
  gap: 1rem;

  > div {
    position: relative;
    display: grid;
    justify-content: center;
    text-align: center;
    padding: ${({ padding }) => padding};

    &::after {
      width: 100%;
      height: 0.25rem;
      background-color: ${({ theme }) => theme.colors.purple};
      content: '';
      bottom: -0.1rem;
      position: absolute;
      left: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.3s ease-in-out;
    }

    &.active,
    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

Tabs.defaultProps = {
  padding: '10px 20px'
};
