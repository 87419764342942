import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import LayoutContext from 'app/contexts/LayoutContext';
import { Navigate } from 'react-router-dom';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  /* ========= useState ========== */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [auth, setAuth] = useState(true);
  const [AuthToken, setAuthToken] = useState({});
  const [User, setUser] = useState({});

  const [message, setMessage] = useState(null);

  /* ========= useEffect ========== */
  useEffect(() => {
    if (auth) {
      setAuthToken(localStorage.getItem('AuthToken'));
      setUser(localStorage.getItem('User'));
    } else {
      setAuthToken(null);
      setUser(null);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('AuthToken')) {
      setAuth(true);
    }
  }, []);

  /* ========= Functions ========== */
  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      let userLoginData = {
        email: 'emmanuelaehis@gmail.com',
        password: 'password'
      };

      let config = {
        method: 'post',
        url: 'https://raven-livestream-bn.herokuapp.com/api/v1/auth/login',
        header: {},
        data: userLoginData
      };

      await axios(config).then(
        (response) => {
          localStorage.setItem(
            'AuthToken',
            `Bearer ${JSON.stringify(response.data.data.token)}`
          );
          const person = {
            ID: response.data.data.user.id,
            name: response.data.data.user.fullName,
            email: response.data.data.user.email,
            username: response.data.data.user.username,
            picture_id: response.data.data.user.avatar,
            walletBalance: response.data.data.user.walletBalance
          };

          localStorage.setItem('User', JSON.stringify(person));
          setMessage('😢 loading');
          setAuth(true);
        },
        (error) => {
          setMessage('😢 Server error');
        }
      );

      setTimeout(() => {
        <Navigate to={'/'} replace />;
      }, 3000);
    } else {
      setMessage('😢 Invalid Email or Password');
    }

    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };

  const handleSignup = () => {};

  const handlePasswordReset = () => {};

  const AuthorizeUser = async () => {
    window.location.pathname = '/';
  };

  const handleLogout = () => {
    setTimeout(() => {
      setAuth(false);
      <Navigate to={'/auth'} replace />;
    }, 3000);
  };

  return (
    <AuthContext.Provider
      value={{
        handleSignup,
        handlePasswordReset,
        handleLogin,
        AuthorizeUser,
        setEmail,
        setPassword,
        auth,
        setAuth,
        message,
        User,
        handleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
