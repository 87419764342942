import React, { useState } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/utilities/Flex';
import { Tabs } from 'components/utilities/Tabs';

import Comments from 'components/GoLive/PaneLayout/Comments/Comments';
import QAComponent from 'components/GoLive/PaneLayout/QA/Q&A';
import RaisedHands from 'components/GoLive/PaneLayout/RaisedHands/RaisedHands';
import Videos from 'components/GoLive/PaneLayout/Videos/Videos';
import InputField from 'components/utilities/InputField';

import { Stack } from 'components/utilities/Stack';
import { Container } from 'components/utilities/Container';

function GoLivePaneLayout() {
  const [toggleState, setToggleState] = useState(1);
  const [streamPane, setStreamPane] = useState(<Comments />);
  const [active, setActive] = useState(1);

  function setup(component, index) {
    setStreamPane(component);
    setActive(index);
    ``;
  }

  function toggleTab(index) {
    setToggleState(index);
  }
  return (
    <StyledStreamingPaneLayout>
      <Tabs
        style={{
          textAlign: 'center',
          fontSize: '1.25rem',
          padding: '.25rem'
        }}
      >
        <div
          className={toggleState === 1 ? 'active' : ''}
          onClick={() => toggleTab(1)}
          style={{ width: '100%' }}
        >
          Backchannels
        </div>
        <div
          className={toggleState === 2 ? 'active' : ''}
          onClick={() => toggleTab(2)}
          style={{ width: '100%' }}
        >
          Audience
        </div>
      </Tabs>

      <Stack className={toggleState === 1 ? 'content active' : 'content'}>
        <div style={{ padding: '.5rem' }}>
          <Flex
            padding={'.5rem 1rem '}
            gap={'1rem'}
            justifyContent={'space-between'}
            className="subTabs"
          >
            <div
              className={active === 1 ? 'active' : ''}
              onClick={() => setup(<Comments />, 1)}
            >
              Comments
            </div>
            <div
              className={active === 2 ? 'active' : ''}
              onClick={() => setup(<QAComponent />, 2)}
            >
              Q & A
            </div>
            <div
              className={active === 3 ? 'active' : ''}
              onClick={() => setup(<RaisedHands />, 3)}
            >
              Raised Hands
            </div>
            <div
              className={active === 4 ? 'active' : ''}
              onClick={() => setup(<Videos />, 4)}
            >
              Videos
            </div>
          </Flex>
        </div>
        <Container>{streamPane}</Container>
      </Stack>

      <Stack className={toggleState === 2 ? 'content active' : 'content'}>
        <Container>
          <h3 className="section__title">Show all Audience</h3>
        </Container>
      </Stack>
      <SendMessage />
    </StyledStreamingPaneLayout>
  );
}

const SendMessage = () => {
  return (
    <StyledSendMessage>
      <InputField label={''} />
    </StyledSendMessage>
  );
};

const StyledSendMessage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  background-color: #000;
`;
const StyledStreamingPaneLayout = styled.div`
  .subTabs {
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.85rem;

    > * {
      width: 100%;
      padding: 0.35rem;
      box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
      text-align: center;

      display: grid;
      place-items: center;
      border-radius: 0.65rem;

      line-height: 1.1;

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        font-weight: bold;
      }

      &.active {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};
        font-weight: bold;
      }
    }
  }

  .content {
    display: none;

    &.active {
      display: initial;
    }
  }
`;

export default GoLivePaneLayout;
